<template>

  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4" style="padding-left: 30px;">
              <div class="inner-heading">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <ul class="portfolio-categ filter" style="padding-left: 60px;">
              <li v-for="MenuSegmento in MenuSegmentos" :key="MenuSegmento.segmentoProdNivel1Id">
                <a href="#" @click="CarregaProdutos(MenuSegmento)" style="font-size: 20px;">{{ MenuSegmento.nomeSegmento }}</a>
              </li>
            </ul>
            <div class="clearfix">
            </div>
            <div class="row">
              <div class="span12">
                <h4 class="heading" style="padding-left: 30px;">{{NomeSegmentoFilho}}</h4>
                <div class="row">
                  <section id="projects">
                    <ul id="thumbs" class="portfolio">
                      <!-- Item Project and Filter Name -->
                      <li class="item-thumbs span3" v-for="produto in Produtos" :key="produto.produtoId">
                        <a class="hover-wrap" :title="produto.nomeProduto"
                          @click="mostraGaleria = !mostraGaleria; urlImg = $apiUrlIndex + produto.imagens[0].urlImg; txtprod = produto.resuno">
                          <span class="overlay-img"></span>
                          <span class="overlay-img-thumb font-icon-plus"></span>
                        </a>
                        <!-- Thumb Image and Description -->
                        <img v-if="produto.imagens!=''" :src="$apiUrlIndex + produto.imagens[0].urlImg" :alt="produto.nomeProduto">
                        <center><span style="font-size: 20px;font-weight: 800;margin-top: 10px;">{{produto.nomeProduto}}</span></center>
                      </li>
                    </ul>
                    <LightBox showCaption :media="[{ thumb: urlImg, src: urlImg, caption: txtprod}]" v-if="mostraGaleria"
                      @onClosed="mostraGaleria = !mostraGaleria">
                    </LightBox>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import Header from '../shared/Header.vue'
import LightBox from 'vue-it-bigger'
//import vSelect from 'vue-select';

export default {
  components: {
    Header,
    LightBox,
    //vSelect
  },
  data() {
    return {
      FundoMenu: '#000000',
      mostraGaleria: false,
      urlImg: '',
      txtprod:'',
      CorDefault: '',
      CaminhoDefault: '',
      Topo: [],
      TituloTopo: '',
      segmentoProdNivel1Id: this.$route.params.id,
      MenuSegmentos: [],
      MenuSegmento: {},
      ProdutosGerais: [],
      Produtos: [],
      ProdutosFiltro: [],
      produto: {},
      ListaEtiquetaImg: [],
      NomeSegmentoFilho: '',
      Ordemselected: 'Ordenar por',
      Ordenar: [
        {
          nome: "Maior Valor",
          valor: 3
        },
        {
          nome: "Menor Valor",
          valor: 4
        }
      ],
    };
  },
  metaInfo() {
    return {
      title: 'Produtos - Dili Foods',
    }
  },
  methods: {
    MudaOrdem() {
      if (this.Ordemselected == 3) {
        this.Produtos.sort(function (a, b) {
          return a.valorAte - b.valorAte;
        }).reverse();
      } else if (this.Ordemselected == 4) {
        this.Produtos.sort(function (a, b) {
          return a.valorAte - b.valorAte;
        });
      } else {
        this.Produtos.sort(function (a, b) {
          if (a.produtoId > b.produtoId) {
            return 1;
          }
          if (a.produtoId < b.produtoId) {
            return -1;
          }
          return 0;
        }).reverse();
      }
    },
    CarregaProdutos(value) {
      this.SegmentoClicado = value.nomeSegmento;
      this.$http
        .get(this.$apiUrl + "/produto/segmentoprodnivel1/" + parseInt(value.segmentoProdNivel1Id, 10))
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.ProdutosGerais = tiu2
          this.Produtos = this.ProdutosGerais.filter(x => x.visivel).sort(function (a, b) {
            if (a.produtoId > b.produtoId) {
              return 1;
            }
            if (a.produtoId < b.produtoId) {
              return -1;
            }
            return 0;
          }).reverse();
          console.log('Produtos')
          console.log(this.Produtos)
        });
    },
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/produtostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

  /*   this.$http
      .get(this.$apiUrl + "/SegmentoProdNivel1/")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
        //this.CarregaProdutos(this.MenuSegmentos[0])
      }); */

      this.$http
      .get(this.$apiUrl + "/produto/")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Produtos = tiu2.filter(x => x.visivel ).sort(function (a, b) {
            if (a.produtoId > b.produtoId) {
              return 1;
            }
            if (a.produtoId < b.produtoId) {
              return -1;
            }
            return 0;
          });
      });

    this.$http
      .get(this.$apiUrl + "/produtotextogeral")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.parcelas = parseInt(tiu2[0].texto1, 10);
        this.parcelasTexto = tiu2[0].texto2;
      });
  }
}
</script>
<style>
/* input[type="search"] {
    display: none !important;
    height: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: rgb(85, 85, 85);
    vertical-align: middle;
    padding: 4px 6px;
    border-radius: 4px;
} */
</style>