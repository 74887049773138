<template>
  <div>
    <!--==========================
    Intro Section
  ============================-->

    <Header :corFundoMenu="FundoMenu" />

    <SliderTopo />
    <section id="content">
      <div class="container-fluid">
        <div class="row" style="justify-content: center;" v-if="Itenshome!=''">
          <div class="span12">
            <div class="row" style="justify-content: center;" >
              <div class="span3" v-for="itemH in Itenshome" :key="itemH.institucionalId">
                <div class="box aligncenter">
                  <div class="aligncenter icon">
                    <router-link to="/services">
                      <i :class="itemH.campoExtra1 + ' icon-circled icon-64 active'"></i>
                    </router-link>
                  </div>
                  <div class="text">
                    <h6>{{itemH.nome}}</h6>
                    <p v-html="itemH.resumo1">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- divider -->
        <div class="row hidden-xs hidden-sm" style="justify-content: center;" v-if="Itenshome!=''">
          <div class="span12">
            <div class="solidline">
            </div>
          </div>
        </div>
        <!-- end divider -->
        <!-- Portfolio Projects -->
        <div class="row" style="justify-content: center;">
          <div class="span12">
            <h4 class="heading" style="padding-left: 30px;">{{tituloProd}}</h4>
            <div class="row" style="justify-content: center;">
              <section id="projects">
                <ul id="thumbs" class="portfolio">
                      <!-- Item Project and Filter Name -->
                      <li class="item-thumbs span3" v-for="produto in produtosDestaque" :key="produto.produtoId">
                        <a class="hover-wrap" :title="produto.nomeProduto" href="/products">
                          <span class="overlay-img"></span>
                          <span class="overlay-img-thumb font-icon-plus"></span>
                        </a>
                        <!-- Thumb Image and Description -->
                        <img v-if="produto.imagens!=''" :src="$apiUrlIndex + produto.imagens[0].urlImg" :alt="produto.nomeProduto">
                        <center><span style="font-size: 20px;font-weight: 800;margin-top: 10px;">{{produto.nomeProduto}}</span></center>
                      </li>
                    </ul>
<!--                     <LightBox showCaption :media="[{ thumb: urlImg, src: urlImg, caption: txtprod}]" v-if="mostraGaleria"
                      @onClosed="mostraGaleria = !mostraGaleria">
                       @click="mostraGaleria = !mostraGaleria; urlImg = $apiUrlIndex + produto.imagens[0].urlImg; txtprod = produto.resuno"
                    </LightBox> -->
              </section>
            </div>
          </div>
        </div>
        <!-- End Portfolio Projects -->
        <!-- divider -->
        <div class="row" style="justify-content: center;">
          <div class="span12">
            <div class="solidline">
            </div>
          </div>
        </div>
        <section style="display: none;">
            <div class="span12">
              <div class="row" style="justify-content: center;" >
                  <center><iframe width="800px" height="380" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="/cotacao.html"></iframe></center>
            </div>
          </div>
        </section>
        <!-- end divider -->
        <ClientesSlider :TituloCli="titulocli"/>
      </div>
    </section>
  </div>
</template>

<script>
import SliderTopo from '../shared/SliderTopo.vue'
import ClientesSlider from '../shared/ClientesSlider.vue'
//import Depoimentos from '../shared/Depoimentos.vue'
import Header from '../shared/Header.vue'
//import CallActionPages from '../shared/CallActionPages.vue'
//import LightBox from 'vue-it-bigger'

export default {
  components: {
    SliderTopo,
    ClientesSlider,
    //Depoimentos,
    Header,
    //CallActionPages,
   // LightBox,
  },
  data() {
    return {
      FundoMenu: "",
      CorDefault: '',
      CaminhoDefault: '',
      mostraGaleria: false,
      Itenshome:[],
      chamadashome:[],
      tituloProd:'',
      titulocli:'',
      produtosDestaque:[],
    };
  },
  metaInfo() {
    return {
      title: 'Home - Dili Foods',
   }
  },
  methods: {
  },
  created() {
    
    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenshome")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Itenshome = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/chamadashome")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.chamadashome = tiu2;
        if (this.chamadashome != null) {
            this.tituloProd = this.chamadashome[0].nome;
            this.titulocli = this.chamadashome[0].campoExtra3;
        }
      });

      this.$http
      .get(this.$apiUrl + "/produto/")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.produtosDestaque = tiu2.filter(x => x.visivel && x.destaque).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });
  }
}

</script>