<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <aside class="left-sidebar">
                <div class="widget">
                  <h5 class="widgetheading">Information</h5>
                  <ul class="recent">
                    <li v-for="itemS in ItensInformacoes" :key="itemS.institucionalId">
                      <h6 style="margin-bottom: 0px !important;"><router-link
                          :to="'/Information/' + itemS.institucionalId + '/' + itemS.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                          {{ itemS.nome }}
                        </router-link>
                      </h6>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div class="span8">
              <article>
                <div class="row" v-if="Info != ''">
                  <div class="span8">
                    <div class="post-image">
                      <div class="post-heading">
                        <h3><a href="">{{ Info.nome }}</a></h3>
                      </div>
                      <img v-if="Info.institucionalImg!=''" :src="$apiUrlIndex + Info.institucionalImg[0].urlImagem" alt="" />
                    </div>
                    <p v-html="Info.resumo1"></p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    //CallActionPages
  },
  data() {
    return {
      InstitucionalId: this.$route.params.id,
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      Topo: [],
      TituloTopo: '',
      ItensInformacoes: [],
      Info: {},
    };
  },
  metaInfo() {
    return {
      title: this.Info.nome + ' - Services - Dili Foods',
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/infotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listainformações")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensInformacoes = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
        if (typeof (this.InstitucionalId) != 'undefined' || this.InstitucionalId != null) {
          this.Info = this.ItensInformacoes.filter(x => x.institucionalId == this.InstitucionalId)[0];
        }
        else {
          this.Info = this.ItensInformacoes[0];
        }
      });


  }
}
</script>

