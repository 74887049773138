<template>
  <div>
    <!-- toggle top area -->
    <div v-if="ItensTopo != '' && mostraTopo" class="hidden-top toggle-link-open hidden-xs hidden-sm">
      <div class="hidden-top-inner container">
        <div class="row">
          <div class="span12">
            <ul>
              <li><strong>{{ ItensTopo[0].campoExtra2 }}</strong></li>
              <li><i class="icon-phone"></i> {{ ItensTopo[0].nome }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end toggle top area -->
    <!-- start header -->
    <header>
      <div class="container hidden-xs hidden-sm">
        <!-- hidden top area toggle link -->
        <div id="header-hidden-link">
          <a href="#" v-if="!mostraTopo" class="toggle-link" title="" @click="mostraTopo = !mostraTopo"><i></i>Open</a>
          <a href="#" v-else class="toggle-link open" title="" @click="mostraTopo = !mostraTopo"><i></i>Open</a>
        </div>
        <!-- end toggle link -->
        <div class="row mt-5" style="margin-bottom: 0px;">
          <div class="col-lg-3 col-md-4">
            <div class="logo">
              <router-link to="/">
                <h1><img :src="imgLogo" :alt="altLogo" :style="{ height: AlturaLogo, width: LarguraLogo }" /></h1>
              </router-link>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="navbar navbar-static-top">
              <div class="navigation">
                <nav>
                  <ul class="nav topnav">
                    <li v-for="item in ItensMenu" :key="item.institucionalId">
                      <router-link :to="item.referencia">{{ item.nome }}</router-link>
                    </li>
                    <li>
                      <a href="#" class="btn btn-block btn-sm" style="background-color:var(--main-bg-color);color:#ffffff;    height: 30px;
    padding: 5px;font-size: 13px;" @click="showModal = true">Language</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- end navigation -->
            </div>
          </div>
        </div>
      </div>
      <div class="container hidden-lg hidden-md">
        <div class="row nomargin">
          <div class="col-6">
            <b-button v-b-toggle.sidebar-1 class="colorlib-nav-toggle" style="margin: 10px;"><i class="fa fa-bars"></i>
              Menu</b-button>
          </div>
          <div class="col-6">
            <div class="logo">
              <router-link router-link to="/">
                <h1><img :src="imgLogo" :alt="altLogo" :style="{ height: AlturaLogo, width: LarguraLogo }" /></h1>
              </router-link>
            </div>
          </div>
        </div>
        <b-sidebar id="sidebar-1" title="" bg-variant="light" text-variant="black" shadow>
          <div class="">
            <aside style="padding-top: 50px;">
              <h1 id="colorlib-logo" style="display: block;text-align: center;">
                <router-link to="/" class="scrollto"
                  style="color: #000;letter-spacing: 2px;padding-right: 0.3em;"><img :src="imgLogo" :alt="altLogo"
                    :style="{ height: AlturaLogo, width: LarguraLogo }" /></router-link>
              </h1>
              <ul id="colorlib-main-menu" class="nav-menu" style="padding-top: 20px;text-align: left;">
                <li style="list-style: none;padding: 5px;" v-for="item in ItensMenu" :key="item.institucionalId">
                  <router-link :to="item.referencia" style="color:#000;font-size: 18px;">{{ item.nome }}</router-link>
                </li>
              </ul>
            </aside>
                      <a href="#" class="btn btn-block btn-sm" style="background-color:var(--main-bg-color);color:#ffffff;    height: 30px;
    padding: 5px;font-size: 13px;" @click="showModal = true">Idioma</a>

          </div>
        </b-sidebar>
      </div>
    </header>
    <Modal v-model="showModal" title="Languages">
      <Translator :countries="arrayOfCountries" @on-country-click="showModal = false" />
    </Modal>
  </div>
</template>

<script>
import { Translator } from 'vue-google-translate';
export default {
  props: {
    corFundoMenu: {
      type: String
    },
  },
  components: {
    Translator
  },
  data() {
    return {
      CorDefault: '',
      CaminhoDefault: '',
      mostraTopo: false,
      Retorno: [],
      imgLogo: '',
      altLogo: '',
      AlturaLogo: '',
      LarguraLogo: '',
      ItensMenu: [],
      ItensTopo: [],
      showModal: false,
      arrayOfCountries: [
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|es",
          title: "Spanish",
        },
        {
          code: 'en|pt',
          title: 'Portuguese',
        },
      ]
    };
  },
  directives: {
    scroll
  },
  methods: {
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/logotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Retorno = tiu2;
        if (this.Retorno != null) {
          if (this.Retorno[0].institucionalImg != null) {
            this.imgLogo = this.$apiUrlIndex + this.Retorno[0].institucionalImg[0].urlImagem;
            this.altLogo = this.Retorno[0].nome;
            this.AlturaLogo = this.Retorno[0].campoExtra1;
            this.LarguraLogo = this.Retorno[0].campoExtra2;
          }
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itensmenu")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensMenu = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });
    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenstopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensTopo = tiu2;
      });

  },
  destroyed() {

  }
}
</script>

<style>
/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}

/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

.navbar-light .navbar-toggler {
  border-color: #ffffff !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width:991px) {
  .fundoCor {
    background-color: #000000;
    padding: 15px;
  }
}
</style>