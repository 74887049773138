<template>
  <div>

    <footer>
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span3">
              <div class="widget">
                <h5 class="widgetheading">Browse pages</h5>
                <ul class="link-list">
                  <li v-for="item in ItensMenu" :key="item.institucionalId">
                    <router-link :to="item.referencia">{{ item.nome }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="span3">
              <div class="widget">
                <h5 class="widgetheading">Our services</h5>
                <ul class="link-list">
                  <li v-for="itemServ in ItensServicos" :key="itemServ.institucionalId">
                    <router-link
                      :to="'/services/' + itemServ.institucionalId + '/' + itemServ.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">{{
                        itemServ.nome
                      }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="span3">
              <div class="widget">
                <h5 class="widgetheading">Information</h5>
                <ul class="link-list">
                  <li v-for="itemInfo in ItensInfo" :key="itemInfo.institucionalId">
                    <router-link
                      :to="'/Information/' + itemInfo.institucionalId + '/' + itemInfo.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">{{
                        itemInfo.nome
                      }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="span3">
              <div class="widget">
                <h5 class="widgetheading">Get in touch with us</h5>
                <address>
                  <strong>
                    <span v-html="Contatos"></span>
                  </strong>
                </address>
                <p>
                  <i class="icon-phone"></i> {{ Telefone }} <br>
                  <i class="icon-envelope-alt"></i> {{ Email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sub-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="span12">
              <div class="span6">
                <div class="copyright">
                </div>
              </div>
              <div class="span6">
                <ul class="social-network">
                  <li v-if="facebook != ''"><a :href="facebook" data-placement="bottom" title="Facebook"
                      target="_blank"><i class="icon-facebook icon-square"></i></a></li>
                  <li v-if="linkedin != ''"><a :href="linkedin" data-placement="bottom" title="Linkedin"
                      target="_blank"><i class="icon-linkedin icon-square"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div v-if="LinkWhats != ''" class="whatsapp-fixo">
      <a :href="LinkWhats" target="_blank">
        <img :src="imgWhats" alt="" />
      </a>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      CorDefault: '',
      CaminhoDefault: '',
      ItensMenu: [],
      ItensServicos: [],
      ItensInfo: [],
      Itensrodape: [],
      Contatos: '',
      Telefone: '',
      Email: '',
      redes: [],
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      whatsapp: '',
      LinkWhats: '',
      imgWhats: '',
    };
  },
  methods: {
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/itensmenu")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensMenu = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });
    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaservicos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listainformações")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensInfo = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itensrodape")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Itensrodape = tiu2;
        if (this.Itensrodape != null) {
          this.Contatos = this.Itensrodape[0].resumo2;
          this.Telefone = this.Itensrodape[0].nome;
          this.Email = this.Itensrodape[0].campoExtra1;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/redes")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.redes = tiu2;
        if (this.redes != null) {
          this.facebook = this.redes[0].nome;
          this.instagram = this.redes[0].referencia;
          this.linkedin = this.redes[0].campoExtra1;
          this.youtube = this.redes[0].campoExtra2;
          this.whatsapp = this.redes[0].campoExtra3;
          this.LinkWhats = this.redes[0].campoExtra4;
          if (this.redes[0].institucionalImg != null) {
            this.imgWhats = this.$apiUrlIndex + this.redes[0].institucionalImg[0].urlImagem;
          }
        }
      });
  }
}
</script>

<style>
footer address strong span p span {
  color: #bbb !important;
}

  /* WHATSAPP FIXO */
  .whatsapp-fixo {
      position: fixed;
      bottom: 80px;
      right: 5px;
      z-index: 999;
  }
</style>