<template>
  <section class="featured ">
    <div id="nivo-slider">
      <b-carousel id="carousel-1" v-model="slide" :interval="5000" indicators fade img-width="1024" img-height="480"
        style="text-shadow: 1px 1px 2px #333;">
        <!-- Slides with custom text -->
        <b-carousel-slide v-for="item in slide.slice(0,10)" :key="item.institucionalId" class="slider-item" :img-src="$apiUrlIndex + item.institucionalImg[0].urlImagem">
          <div class="sl-slider">
              <h2><strong>{{item.nome}}</strong></h2>
              <blockquote>
                <p v-html="item.resumo1">
                </p>
              </blockquote>
            </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sliding: null,
      CorDefault: '',
      CaminhoDefault: '',
      slide:[],
    };
  },
  methods: {},
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/slide")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.slide = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
        console.log(this.slide)
      });
  },
}
</script>

<style>

.sl-slider blockquote {
    color: #fff;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    padding-top: 10px;
}

.sl-slider h2 {
    font-size: 100px;
    line-height: 1em;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
    letter-spacing: -2px;
    color:#ffffff;
}
.sl-slider blockquote {
    color: #fff;
    margin: 0 auto;
    position: relative;
    z-index: 100;
     padding-top: 0px; 
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 480px;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.carousel-caption {
  position: absolute;
  right: 5% !important;
  top: 100px !important;
  left: 10% !important;
  padding-top: 2rem !important;
  padding-bottom: 1.25rem !important;
  color: #fff;
  text-align: left !important;
  background-color: transparent !important;
}


@media (max-width:1200px) {

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    top: 50px;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
}

@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }

  .ajustafontecel {
    font-size: 18px !important;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    height: 145px !important;
    overflow: hidden;
}

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    top: 10px !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    height: 200px;
  }
  .sl-slider h2 {
    font-size: 20px !important;
    line-height: 1em;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
    letter-spacing: -2px;
    color: #ffffff;
}

blockquote p h3{
    font-size: 14px !important;
    font-weight: 400;
    font-family: 'Noto Serif', serif;
    font-style: italic;
    padding-left: 0;
    color: #a2a2a2;
    line-height: 1.6em;
    border: none;
}
}
</style>