<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <aside class="left-sidebar">
                <div class="widget">
                  <h5 class="widgetheading">Our services</h5>
                  <ul class="recent">
                    <li v-for="item in items" :key="item.institucionalId">
                      <h6 style="margin-bottom: 0px !important;"><router-link
                          :to="'/services/' + item.institucionalId + '/' + item.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                          {{ item.nome }}
                        </router-link>
                      </h6>
                      <p v-html="item.resumo1"></p>
                    </li>
                  </ul>
                  <pagination :items="ItensServicos" :pageSize="20" :maxPages="5" :initialPage="1"
                    :labels="minhasLabels" @changePage="changePage"></pagination>
                </div>
              </aside>
            </div>
            <div class="span8">
              <article>
                <div class="row" v-if="Servico != ''">
                  <div class="span8">
                    <div class="post-image">
                      <div class="post-heading">
                        <h3><a href="">{{ Servico.nome }}</a></h3>
                      </div>
                      <img v-if="Servico.institucionalImg != ''"
                        :src="$apiUrlIndex + Servico.institucionalImg[0].urlImagem" alt="" />
                    </div>
                    <p v-html="Servico.resumo2"></p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'
import Pagination from "fbr.fullbar.paginacao";

export default {
  components: {
    Header,
    Pagination
  },
  metaInfo() {
    return {
      title: this.Servico.nome + ' - Services - Dili Foods',
    }
  },
  data() {
    return {
      items: [],
      InstitucionalId: this.$route.params.id,
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      Topo: [],
      TituloTopo: '',
      ItensServicos: [],
      Servico: {},
      minhasLabels: {
        first: 'first',
        last: 'last',
        previous: '<',
        next: '>'
      }
    };
  },
  methods: {
    changePage(items) {
      // Atualiza pagina com o array items
      this.items = items;
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaservicos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });

        if (typeof (this.InstitucionalId) != 'undefined' || this.InstitucionalId != null) {
          this.Servico = this.ItensServicos.filter(x => x.institucionalId == this.InstitucionalId)[0];
        }
        else {
          this.Servico = this.ItensServicos[0];
          console.log(this.Servico)
        }
      });


  }
}
</script>

