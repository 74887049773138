<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />
    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" style="padding-top: 100px;padding-bottom: 100px;">
      <div class="container">
        <header class="section-header">
          <h3>{{ titulo }}</h3>
          <p v-html="texto"></p>
        </header>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
export default {
  components: {
    Header
  },
  data() {
    return {
      FundoMenu: '#000000',
      Topo: [],
      TituloTopo: '',
      TextoContato: [],
      titulo: '',
      texto: '',
    };
  },
  metaInfo() {
    return {
      title: 'Send contact- Dili Foods',
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/contatotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/contatoenviado")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.TextoContato = tiu2;
        if (this.TextoContato != null) {
          this.titulo = this.TextoContato[0].nome;
          this.texto = this.TextoContato[0].resumo1;
        }
      });
  }
}
</script>

