<template>


<section class="callaction">
      <div class="container">
        <div class="row">
          <div class="span12">
            <div class="big-cta">
              <div class="cta-text">
                <h3>Entre em contato <span class="highlight"><strong>Conosco</strong></span> para mais Informações!</h3>
              </div>
              <div class="cta floatright">
                <router-link to="/contact" class="btn btn-large btn-theme btn-rounded">Solicite um Orçamento</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--     :style="{backgroundImage: 'url(' + $CaminhoImages + CaminhoDefault + '/callaction/callaction1.jpg)', backgroundPosition: 'center'}" -->
</template>

<script>
export default {
  components: { 
  },
   data() {
            return {
              CorDefault: '',
            CaminhoDefault:'',
            }
        },
   created() {
    let dadosArmazenados = sessionStorage.getItem(this.$ModeloSite);
      if (dadosArmazenados != null && dadosArmazenados.length > 0) {
        dadosArmazenados = JSON.parse(dadosArmazenados);
        this.CorDefault = dadosArmazenados.CorDefault;
        this.CaminhoDefault = dadosArmazenados.CaminhoDefault;
      }

        },
}

</script>