<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{TituloTopo}}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container">
        <div class="row" style="text-align: center;">
            <div class="span12">
              <h2>{{TituloTexto}}</h2>
              <p v-html="textoSobre"></p>
            </div>
            <div class="span12 mt-5">
              <img :src="imgSobre" alt="" />
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    //CallActionPages
  },
  data() {
    return {
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      Topo:[],
      TituloTopo:'',
      SobreTexto:[],
      TituloTexto:'',
      textoSobre:'',
      imgSobre:'',
    };
  },
  metaInfo() {
    return {
      title: 'Mercados - Dili Foods',
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/mercadostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
            this.TituloTopo = this.Topo[0].nome;
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/mercados")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.mercados = tiu2;
        if (this.mercados != null) {
            this.TituloTexto = this.mercados[0].nome;
            this.textoSobre = this.mercados[0].resumo1;
            if (this.mercados[0].institucionalImg != null) {
            this.imgSobre = this.$apiUrlIndex + this.mercados[0].institucionalImg[0].urlImagem;
            }
        }
      });
  }
}
</script>

