<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{ TituloTopo }} </h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <aside class="left-sidebar">
                <div class="widget">
                  <h5 class="widgetheading">Latest posts</h5>
                  <ul class="recent">
                    <li v-for="itemB in items" :key="itemB.institucionalId">
                      <router-link :to="'/blog/' + itemB.institucionalId + '/' + itemB.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                          <img v-if="itemB.institucionalImg!=''" :src="$apiUrlIndex + itemB.institucionalImg[0].urlImagem" class="pull-left" alt="" style="width: 100px;" />
                      </router-link>
                      <h6>
                        <router-link :to="'/blog/' + itemB.institucionalId + '/' + itemB.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                          {{itemB.nome}}
                        </router-link>
                      </h6>
                    </li>
                  </ul>
                  <pagination :items="ItensBlog" :pageSize="20" :maxPages="5" :initialPage="1"
                    :labels="minhasLabels" @changePage="changePage"></pagination>
                </div>
              </aside>
            </div>
            <div class="span8">
              <article>
                <div class="row" v-if="Blog.institucionalImg != ''">
                  <div class="span8">
                    <div class="post-image">
                      <div class="post-heading">
                        <h3><a href="">{{ Blog.nome }}</a></h3>
                      </div>
                      <img v-if="Blog.institucionalImg!=''" :src="$apiUrlIndex + Blog.institucionalImg[0].urlImagem" alt="" />
                    </div>
                    <p v-html="Blog.resumo1"></p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'
import Pagination from "fbr.fullbar.paginacao";

export default {
  components: {
    Header,
    Pagination
  },
  data() {
    return {
      items: [],
      InstitucionalId: this.$route.params.id,
      minhasLabels: {
        first: 'first',
        last: 'last',
        previous: '<',
        next: '>'
      },
      CorDefault: '',
      CaminhoDefault: '',
      FundoMenu: '#000000',
      Topo: [],
      TituloTopo: '',
      ItensBlog:[],
      Blog:{},
    };
  },
  metaInfo() {
    return {
      title: this.Blog.nome + ' - Blog - Dili Foods',
    }
  },
  methods: {
    changePage(items) {
      // Atualiza pagina com o array items
      this.items = items;
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/blogtopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/blog")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensBlog = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
        if (typeof (this.InstitucionalId) != 'undefined' || this.InstitucionalId != null) {
          this.Blog = this.ItensBlog.filter(x => x.institucionalId == this.InstitucionalId)[0];
        }
        else {
          this.Blog = this.ItensBlog[0];
        }
      });
  }
}
</script>

<style>

</style>

