<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section id="inner-headline">
      <div class="container-fluid">
        <div class="row">
          <div class="span12">
            <div class="span4">
              <div class="inner-heading">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
            <div class="span8">
              <ul class="breadcrumb">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container" style="padding-top: 40px;">
      <div class="row">
        <div class="span12">
          <h4 style="padding-left: 60px;">{{ TituloContato }}</h4>
          <div class="contactForm">
            <div class="row">
              <div class="span4 form-group" style="width: 100%;">
                <input type="text" v-model="Nome" name="name" class="form-control" id="name" placeholder="Your Name"
                  data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validation"></div>
              </div>
              <div class="span4 form-group" style="width: 100%;">
                <input type="email" v-model="Email" class="form-control" name="email" id="email"
                  placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validation"></div>
              </div>
              <div class="span4 form-group" style="width: 100%;">
                <input type="text" v-model="Telefone" class="form-control" name="subject" id="subject"
                  placeholder="Your Telephone" data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject" />
                <div class="validation"></div>
              </div>
              <div class="span12 margintop10 form-group">
                <textarea class="form-control" v-model="Mensagem" name="message" rows="12" data-rule="required"
                  data-msg="Please write something for us" placeholder="Message"></textarea>
                <div class="validation"></div>
                <p class="text-center">
                  <button class="btn btn-large btn-theme margintop10" @click="Enviar()">Submit
                    message</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="ftco-section ftco-no-pb ftco-no-pt">
      <div class="container-fluid px-0 ">
        <div class="row justify-content-center nomargin">
          <div class="col-md-12">
            <iframe
              :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + TextoEndereco"
              width="100%" height="650px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0">
            </iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {
      FundoMenu: '#000000',
      Topo: [],
      TituloTopo: '',
      DadosContato: [],
      TituloContato: '',
      Nome: '',
      Email: '',
      Telefone: '',
      Mensagem: '',
      Contato: {
        NomeEmpresa: "",
        Assunto: "Contact",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      },
      TextoEndereco: '',
    };
  },
  metaInfo() {
    return {
      title: 'Contact - Dili Foods',
    }
  },
  methods: {
    Enviar() {
      if (this.Nome == '' || this.Telefone == '' || this.Email == '' || this.Mensagem == '') {
        this.$mensagem_normal("Fill in all fields");
      } else {
        let tmp = { ToEmail: this.Contato.Destinatario, Subject: 'Contact Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | E-mail: " + this.Email + " | Mensagem:  " + this.Mensagem };
        this.$http
          .post(
            this.$apiUrl + "/contato/enviamail/" + this.Contato.NomeEmpresa, tmp
          )
          .then(
            (response) => {
              if (response.ok) {
                this.Nome = '';
                this.Telefone = '';
                this.Email = '';
                this.Mensagem = '';
                this.$redirect("/contactSend");
              }
            },
            (error) => {
              console.log(error);
              this.Resposta = error.bodyText;
            }
          );
      }
    },
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/contatotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/dadoscontato")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.DadosContato = tiu2;
        if (this.DadosContato != null) {
          this.TituloContato = this.DadosContato[0].nome;
          this.Contato.Destinatario = this.DadosContato[0].referencia;
          this.Contato.NomeEmpresa = this.DadosContato[0].campoExtra5;
          this.TextoEndereco = this.DadosContato[0].campoExtra4;
        }
      });
  }
}
</script>
