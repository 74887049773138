import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Sobre from '@/components/site/views/Sobre.vue'
import Servicos from '@/components/site/views/Servicos.vue'
import ServicosDetalhes from '@/components/site/views/ServicosDetalhes.vue'
import Produtos from '@/components/site/views/Produtos.vue'
import ProdutosDetalhes from '@/components/site/views/ProdutosDetalhes.vue'
import Equipe from '@/components/site/views/Equipe.vue'
import Blog from '@/components/site/views/Blog.vue'
import BlogDetalhes from '@/components/site/views/BlogDetalhes.vue'
import Contato from '@/components/site/views/Contato.vue' 
import ContatoEnviado from '@/components/site/views/ContatoEnviado.vue' 
import Informacoes from '@/components/site/views/Informacoes.vue' 
import Mercados from '@/components/site/views/Mercados.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
   {path: '/', component: Home},
   {path: '/aboutus', component: Sobre, params : false},
   {path: '/services', component: Servicos},
   {path: '/services/:id/:nome', component: Servicos},
   {path: '/servicesDetails', component: ServicosDetalhes},
   {path: '/products', component: Produtos},
   {path: '/productDetails', component: ProdutosDetalhes},
   {path: '/team', component: Equipe},
   {path: '/blog', component: Blog},
   {path: '/blog/:id/:nome', component: Blog},
   {path: '/blogDetails', component: BlogDetalhes},
   {path: '/contact', component: Contato}, 
   {path: '/contactSend', component: ContatoEnviado}, 
   {path: '/Information/:id/:nome', component: Informacoes},
   {path: '/market', component: Mercados}, 
  ]
})