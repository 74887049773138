<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section class="hero-wrap hero-wrap-2"
      :style="{ backgroundImage: 'url(' + $CaminhoImages + CaminhoDefault + '/sobre/sobretopo.jpg' + ')' }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">Produtos</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-counter" style="padding-top: 100px;">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-2 d-flex">
          <div class="col-md-6 align-items-stretch d-flex">
            <div class="img img-video d-flex align-items-center"
              :style="{ backgroundImage: 'url(' + $CaminhoImages + CaminhoDefault + '/produtos/produto1.jpg)', backgroundPosition: 'center' }">
            </div>
          </div>
          <div class="col-md-6 heading-section  pl-lg-5 pt-md-0 pt-5">
            <h2 class="mb-4">Nome do Produto</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pulvinar posuere volutpat. Etiam congue est
              id urna interdum, in accumsan est maximus. Cras id malesuada turpis. Maecenas velit sapien, eleifend vel
              dolor id, ornare consequat nunc. Nunc sit amet porta lectus.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pulvinar posuere volutpat. Etiam congue est
              id urna interdum, in accumsan est maximus. Cras id malesuada turpis. Maecenas velit sapien, eleifend vel
              dolor id, ornare consequat nunc. Nunc sit amet porta lectus.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'
//import LightBox from 'vue-it-bigger'

export default {
  components: {
    Header,
    // CallActionPages,
    // LightBox
  },
  data() {
    return {
      FundoMenu: '#000000',
      mostraGaleria: false,
      urlImg: '',
      CorDefault: '',
      CaminhoDefault: '',
    };
  },
  created() {
    let dadosArmazenados = sessionStorage.getItem(this.$ModeloSite);
    if (dadosArmazenados != null && dadosArmazenados.length > 0) {
      dadosArmazenados = JSON.parse(dadosArmazenados);
      this.CorDefault = dadosArmazenados.CorDefault;
      this.CaminhoDefault = dadosArmazenados.CaminhoDefault;
    }
  }
}
</script>

