<template>
  <section id="clients" class="mb-5" v-if="Clientes!=''">
    <div class="row" style="justify-content: center;">
      <div class="span12">
            <h4 style="padding-bottom: 20px;">{{TituloCli}}</h4>

      <carousel :autoplay="true" :responsive="{ 0: { items: 2, nav: false,margin: 20 }, 600: { items: 4, nav: false, margin: 20 } }">
            <img v-for="itemC in Clientes" :key="itemC.institucionalId" :src="$apiUrlIndex + itemC.institucionalImg[0].urlImagem" alt="" style="border:1px solid #cccccc;">
      </carousel>
    </div>
    </div>
  </section><!-- #clients -->
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  props: {
    TituloCli: {
      type: String
    }
  },
  components: {
    carousel
  },
  data() {
    return {
      CorDefault: '',
      CaminhoDefault:'',
      Clientes:[],
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaclientes")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Clientes = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });
  },
}

</script>

<style>
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow-x: hidden !important;
    -webkit-transform: translate3d(0px, 0px, 0px);
}



</style>