<template>
  <div id="app">
    <div id="wrapper">
     <transition name="fadeIn">
       <router-view :key="$route.fullPath"></router-view>
     </transition>
     <Footer /> 
    </div>
  </div>
</template>
<script>

  import Footer from './components/site/shared/Footer.vue'
  export default {
    name: 'App',
    components: {
      Footer 
    },
    data() {
      return {
        colour:'',
        CorDefault: '',
        CaminhoDefault:'',
        DadosCompra: {
          CorDefault:'',
          CaminhoDefault:''
        }     
      }
    },
  /*metaInfo() {
   return {
     title: 'Sobre - Dili Foods',
      meta: [
            {name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline},
            {property: 'og:title', content: this.userData.name + ' - Epiloge'},
            {property: 'og:site_name', content: 'Epiloge'},
            {property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline},
            {property: 'og:type', content: 'profile'},
            {property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username},
            {property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }    
        ] 
script: [{
        innerHTML: 'console.log("I am in body");',
        type: 'text/javascript',
        pbody: true
      }] 
   }
 },*/
    methods: {
      ValidaCor(){
        var css = document.createElement('style');
        css.type = 'text/css';
        var estilos = ":root { --main-bg-color: " + this.CorPrincipal + "; --main-bg-colorSec : " + this.CorSecundaria + "}";
        css.appendChild(document.createTextNode(estilos));
        document.getElementsByTagName("head")[0].appendChild(css);
      }
    },
    created() {   
      
      this.$http
                .get(this.$apiUrl + "/institucional/telas/logotopo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.CorPrincipal = this.Retorno[0].cor1;
                        this.CorSecundaria = this.Retorno[0].cor2;
                        this.ValidaCor();
                    }

                });
      
    },
    beforeDestroy () {
      sessionStorage.removeItem(this.$ModeloSite);
    },
  }
</script>

<style>
/*   :root {
  --main-bg-color: 'v-bind(colour)';
} 
 */
  /* css tmp */
  @import '/css/bootstrap.css';
  @import '/css/bootstrap-responsive.css';
  @import '/css/fancybox/jquery.fancybox.css';
  @import '/css/jcarousel.css';
  @import '/css/flexslider.css';
  @import '/css/style.css';
  @import '/skins/default.css';
 
  /*---------- Animations */

  .page-item.active .page-link {
    z-index: 3;
    color: #fff ;
    background-color: var(--main-bg-color) !important;
    border-color: var(--main-bg-color) !important;
}

  p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

 a {
   text-decoration: none !important;
 }

 .span3 {
    width: 270px !important; 
}

.span12 {
    width: 1270px !important; 
}

.icon-64.icon-circled {
    border-radius: 46px !important;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
}

.icon-64 {
    width: 80px;
    height: 80px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
}

 .owl-theme .owl-dots .owl-dot span {
    width: 0px !important;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }

    .span3 {
    width: 320px !important; 
}

.span12 {
    width: 100% !important; 
}
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
    .nav-menu > li {
    margin-left: 0px !important;
}
    
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
}
</style>
